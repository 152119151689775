var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Xử lý cấp tài khoản",
            visible: _vm.dialogVisible,
            width: "850px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "userData",
              attrs: { model: _vm.userData, rules: _vm.rules },
            },
            [
              _c("el-row", [
                _c(
                  "span",
                  { staticStyle: { color: "#e51d4c", "font-size": "16px" } },
                  [
                    _vm._v(
                      'Đã tồn tại tài khoản "' +
                        _vm._s(_vm.userData.phone) +
                        '" trên hệ thống. Xin vui lòng lựa\n          chọn các bước tiếp theo để cấp tài khoản!'
                    ),
                  ]
                ),
              ]),
              _c(
                "el-row",
                { attrs: { gutter: 100 } },
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content-over bg-over-1" },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "incorrect" },
                            model: {
                              value: _vm.phoneCorrect,
                              callback: function ($$v) {
                                _vm.phoneCorrect = $$v
                              },
                              expression: "phoneCorrect",
                            },
                          },
                          [
                            _vm._v(
                              'Số điện thoại "' +
                                _vm._s(_vm.userData.phone) +
                                '" không đúng'
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content-over bg-over-2" },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "correct" },
                            model: {
                              value: _vm.phoneCorrect,
                              callback: function ($$v) {
                                _vm.phoneCorrect = $$v
                              },
                              expression: "phoneCorrect",
                            },
                          },
                          [
                            _vm._v(
                              'Số điện thoại "' +
                                _vm._s(_vm.userData.phone) +
                                '" đã đúng'
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm.phoneCorrect == "incorrect"
                ? _c(
                    "span",
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 100 } },
                        [
                          _c(
                            "el-col",
                            [
                              _c("span", { staticClass: "space-right" }, [
                                _vm._v("Nhập số điện thoại mới"),
                              ]),
                              _c("el-input", {
                                staticClass: "space-right",
                                staticStyle: { width: "200px" },
                                attrs: {
                                  type: "number",
                                  min: "0",
                                  max: "0",
                                  placeholder: "Nhập số điện thoại",
                                },
                                model: {
                                  value: _vm.newPhone,
                                  callback: function ($$v) {
                                    _vm.newPhone = $$v
                                  },
                                  expression: "newPhone",
                                },
                              }),
                              _c(
                                "el-button",
                                {
                                  staticClass: "space-right",
                                  attrs: { type: "success", size: "medium" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.checkNewPhoneMethod()
                                    },
                                  },
                                },
                                [_vm._v("Kiểm tra số")]
                              ),
                              _vm.checkUsernameExist == "notExist"
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "space-right",
                                      attrs: {
                                        type: "success",
                                        size: "medium",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateNewPhoneMethod()
                                        },
                                      },
                                    },
                                    [_vm._v("Cập nhật số mới")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-row", [
                        _vm.checkUsernameExist == "notExist"
                          ? _c("span", { staticStyle: { color: "#46b74a" } }, [
                              _vm._v(
                                'Số mới nhập đã đúng, bạn có thể ấn "Cập nhật số mới" để thay đổi tài khoản\n            cho người dùng!'
                              ),
                            ])
                          : _vm.checkUsernameExist == "exist"
                          ? _c("span", { staticStyle: { color: "#e46d2b" } }, [
                              _vm._v(
                                "Số mới nhập đã có trên hệ thống, xin vui lòng lựa chọn số khác!"
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                : _vm.phoneCorrect == "correct"
                ? _c(
                    "span",
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 100 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    content:
                                      "Các thông tin của người dùng cũ có tài khoản ứng với SĐT " +
                                      _vm.userData.phone +
                                      " sẽ được giữ lại",
                                    placement: "right",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "oldAccount" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.showAccountDuplicate()
                                        },
                                      },
                                      model: {
                                        value: _vm.typeAccount,
                                        callback: function ($$v) {
                                          _vm.typeAccount = $$v
                                        },
                                        expression: "typeAccount",
                                      },
                                    },
                                    [_vm._v("Sử dụng tài khoản cũ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    content:
                                      "Các thông tin của người dùng cũ có tài khoản ứng với SĐT " +
                                      _vm.userData.phone +
                                      " sẽ không được giữ lại",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "newAccount" },
                                      model: {
                                        value: _vm.typeAccount,
                                        callback: function ($$v) {
                                          _vm.typeAccount = $$v
                                        },
                                        expression: "typeAccount",
                                      },
                                    },
                                    [_vm._v("Cấp tài khoản mới")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.typeAccount == "newAccount" ||
                      (_vm.typeAccount == "oldAccount" &&
                        _vm.userAccountList != "")
                        ? _c(
                            "span",
                            [
                              _c("el-row", [
                                _c("span", [
                                  _vm._v(
                                    '\n              Bước 1: Ấn "Gửi" để gửi mã xác thực tới số điện thoại "' +
                                      _vm._s(_vm.userData.phone) +
                                      '".\n            '
                                  ),
                                ]),
                              ]),
                              _c("el-row", [
                                _c("span", [
                                  _vm._v(
                                    '\n              Bước 2: Liên hệ với nhân viên có số điện thoại "' +
                                      _vm._s(_vm.userData.phone) +
                                      '" để\n              lấy mã xác thực được gửi qua tin nhắn.\n            '
                                  ),
                                ]),
                              ]),
                              _c("el-row", [
                                _c("span", [
                                  _vm._v(
                                    '\n              Bước 3: Nhập mã xác thực vào ô "Nhập mã xác thực" và ấn nút "Xác thực" để\n              tiến hành cấp tài khoản.\n            '
                                  ),
                                ]),
                              ]),
                              _c(
                                "el-row",
                                [
                                  !_vm.isTimer
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "space-right",
                                          attrs: {
                                            type: "success",
                                            size: "medium",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.sendSMSMethod()
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-s-promotion",
                                          }),
                                          _vm._v("\n              Gửi"),
                                        ]
                                      )
                                    : _c(
                                        "el-button",
                                        {
                                          staticClass: "space-right",
                                          attrs: {
                                            type: "success",
                                            size: "medium",
                                            disabled: "",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-s-promotion",
                                          }),
                                          _vm._v(
                                            "\n              Gửi\n            "
                                          ),
                                        ]
                                      ),
                                  _c("el-input", {
                                    staticClass: "space-right",
                                    staticStyle: { width: "170px" },
                                    attrs: {
                                      maxlength: "6",
                                      clearable: "",
                                      placeholder: "Nhập mã xác thực",
                                    },
                                    on: {
                                      clear: function ($event) {
                                        return _vm.clearCodeMethod()
                                      },
                                    },
                                    model: {
                                      value: _vm.verifyCode,
                                      callback: function ($$v) {
                                        _vm.verifyCode =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "verifyCode",
                                    },
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "space-right",
                                      attrs: {
                                        disabled:
                                          _vm.verifyCode.length == 6
                                            ? false
                                            : true,
                                        type: "success",
                                        size: "medium",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.verificationMethod()
                                        },
                                      },
                                    },
                                    [_vm._v("\n              Xác thực")]
                                  ),
                                ],
                                1
                              ),
                              _c("el-row", [
                                _vm.isTimer
                                  ? _c("span", [
                                      _vm._v("Mã xác thực đã được gửi!"),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c("el-row", [
                                _vm.checkIncorrectCode
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#eb2020" } },
                                      [
                                        _vm._v(
                                          "Mã xác thực không đúng, xin vui lòng nhập lại!"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialogByButton("userData")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("AccountEmployeeDuplicate", {
        ref: "AccountEmployeeDuplicate",
        attrs: { dialogVisible: _vm.showEmployeeDuplicate },
        on: { "dialog-close": _vm.dialogCloseEmployeeMethod },
      }),
      _c("AccountKidsDuplicate", {
        ref: "AccountKidsDuplicate",
        attrs: { dialogVisible: _vm.showKidsDuplicate },
        on: { "dialog-close": _vm.dialogCloseKidsMethod },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }