var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Danh sách học sinh trùng số điện thoại: " + _vm.phoneDupliate,
        visible: _vm.dialogVisible,
        width: "1220px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "15vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-table",
        {
          ref: "multipleTable",
          attrs: {
            data: _vm.responseList,
            "highlight-current-row": "",
            "cell-style": _vm.tableRowStyle,
            "header-cell-style": _vm.tableHeaderColor,
            "max-height": _vm.$tableMaxHeight,
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "STT",
              width: "50",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "fullName", label: "Họ tên", "min-width": "170" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "representation",
              label: "Người đại diện",
              align: "center",
              "min-width": "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "phone",
              align: "center",
              label: "Số điện thoại",
              width: "110",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "username",
              align: "center",
              label: "Tài khoản",
              width: "110",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "100",
              prop: "kidStatus",
              label: "Trạng thái",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày sinh", width: "130", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(scope.row.birthDay))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo", width: "150", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("formatDateTime")(scope.row.createdDate))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Tài khoản", width: "90", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.eixstAccount
                      ? _c("span", [_vm._v("Đã có")])
                      : _c("span", [_vm._v("Chưa có")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "130", label: "Chọn", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-checkbox", {
                      model: {
                        value: scope.row.status,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.loadingButton,
                type: "danger",
                size: "medium",
              },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "success", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.submitForm()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("OK")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }