var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Cập nhật số điện thoại nhận tin nhắn SMS",
        visible: _vm.dialogVisible,
        width: "550px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "15vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "userData",
          attrs: {
            model: _vm.userData,
            "label-width": "140px",
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c("el-form-item", { attrs: { label: "Số điện thoại cũ" } }, [
            _c("span", [_vm._v(_vm._s(_vm.userData.oldPhone))]),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "Số điện thoại mới", prop: "newPhone" } },
            [
              _c("el-input", {
                attrs: {
                  type: "number",
                  min: "0",
                  max: "0",
                  placeholder: "Nhập Số điện thoại nhận SMS",
                },
                model: {
                  value: _vm.userData.newPhone,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.userData,
                      "newPhone",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "userData.newPhone",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                loading: _vm.loadingButton,
                size: "medium",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.save")))]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }